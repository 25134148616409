import { ReactComponent as PondooLogo } from "../assets/pondoo_logo.svg";
import StandardButton from "../components/UI/StandardButton";
import authService from "../service/auth";

const SSOLogin = () => {
  return (
    <section className="flex flex-col items-center justify-center w-full h-[100vh]">
      <PondooLogo />
      <div className="flex flex-col gap-[var(--gap-md)] mt-8"></div>
      <StandardButton
        onClick={() => authService.performLogin()}
        variant="SECONDARY"
        width="320px"
      >
        Login
      </StandardButton>
    </section>
  );
};

export default SSOLogin;
