export default function PageWrapper({
  children,
  classes,
}: {
  children: React.ReactNode | React.ReactNode[];
  classes?: string;
}) {
  const classList = `PageWrapper p-md w-full ${
    classes ? classes : null
  }`;
  return <article className={classList}>{children}</article>;
}
