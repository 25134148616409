import HomePage from "./pages/HomePage";
import { NotFound } from "./pages/NotFound";
import SSOLogin from "./pages/SSOLogin";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter(
  [
    { path: "/", element: <SSOLogin /> },
    { path: "/home", element: <HomePage /> },
    { path: "*", element: <NotFound /> },
  ],
  { basename: "/login" },
);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
