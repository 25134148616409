import PageWrapper from "../components/UI/PageWrapper";
import { menuItemWithIcon } from "../types/common";
import { useEffect, useState } from "react";
import authService from "../service/auth";
import Spinner from "../components/UI/Spinner";

export default function HomePage() {
  const menu: menuItemWithIcon[] = [
    {
      title: "Analytical",
      redirectUrl: "#",
    },
    {
      title: "Operator",
      redirectUrl: window._env_.REACT_APP_OPERATOR_URL,
    },
  ];

  // Todo: once finalized, move login logic into service class.
  const getToken = async () => {
    const tokens = await authService.getAccessToken();
    if (!tokens) console.error("Couldn't reach auth server");

    const {access_token, refresh_token} = tokens!;
    window.location.href = `${window._env_.REACT_APP_OPERATOR_URL}?token=${access_token}&refreshToken=${refresh_token}`;
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <PageWrapper classes="flex flex-col items-center justify-center gap-40 w-full h-[100vh]">
      <Spinner />
    </PageWrapper>
  );
}
