class PKCEUtils {

	static generateRandomString(length: number) : string {
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const values = crypto.getRandomValues(new Uint8Array(length));
		return values.reduce((acc, x) => acc + possible[x % possible.length], "");
	}

	static codeVerifier() : string {
		return PKCEUtils.generateRandomString(128);
	}

	static async sha256(plain: any) {
		const encoder = new TextEncoder();
		const data = encoder.encode(plain);
		return window.crypto.subtle.digest('SHA-256', data);

	}

	static base64encode(input: any) {

		return btoa(String.fromCharCode(...new Uint8Array(input)))
			.replace(/\+/g, '-')
			.replace(/\//g, '_')
			.replace(/=+/, '');
	}

	static async generatePKCE() : Promise<string[]> {

		let code_verifier = PKCEUtils.codeVerifier();
		let hashed = await PKCEUtils.sha256(code_verifier);
		let code_challenge = await PKCEUtils.base64encode(hashed);

		let result = [code_verifier, code_challenge];

		return result;
	}
}

export default PKCEUtils;