import { configureStore, combineReducers } from "@reduxjs/toolkit";
import apiConfigurationstate from "./apiConfigurationstate";

const store = configureStore({
  reducer: combineReducers({
    apiConfigurationstate,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
