import buttonStyles from "../../styles/buttons.module.css";
import { ButtonVariantType } from "../../types/buttons";

const StandardButton = ({
  icon,
  children,
  variant,
  onClick,
  classes,
  disable,
  width,
}: {
  icon?: React.ReactNode;
  children: React.ReactNode;
  variant: ButtonVariantType;
  onClick: () => void;
  classes?: string;
  disable?: boolean;
  width?: string;
}) => {
  return (
    <button
      disabled={disable}
      onClick={onClick}
      className={`${buttonStyles.standardButton} ${
        variant === "PRIMARY"
          ? buttonStyles.standardButtonPrimary
          : buttonStyles.standardButtonSecondary
      } ${classes ? classes : null}`}
      style={{ width: width }}
    >
      {children}
      {icon}
    </button>
  );
};

export default StandardButton;
