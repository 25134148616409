import axios from "axios";
import { ApiFailure, ApiResult } from "../types/auth";

export const baseURL = process.env.REACT_APP_FLIGHT_API;

const api = axios.create({
  baseURL,
});

// To do: add return type if needed
export const postData = async <T>(
  url: string,
  data: any,
  headers?: Record<string, string>
): Promise<ApiResult<T>> => {
  try {
    const response = await api.post(url, data, { headers });
    return { success: true, data: response.data } as ApiResult<T>;
  } catch (err: any) {
    console.log("Error", err);
    // Do your errorhandling here
    return { success: false } as ApiFailure;
  }
};

export default api;
