import { ApiSuccess, AuthCodeResponse } from "../types/auth";
import PKCEUtils from "../utils/PKCEUtils";
import { postData } from "./api";

export type TokenResponse = {
  access_token: string;
  refresh_token: string;
};

class authService {
  static authURL = window._env_.REACT_APP_AUTH_URL!;
  static redirectURI = window._env_.REACT_APP_REDIRECT_URI!;
  static clientId = window._env_.REACT_APP_CLIENT_ID!;
  static clientSecret = window._env_.REACT_APP_CLIENT_SECRET!;
  static tokenURL = window._env_.REACT_APP_TOKEN_URL!;

  static headers: Record<string, string> = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  static performLogin = async () => {
    let pkce = await PKCEUtils.generatePKCE();

    let code_verifier = pkce[0];
    let code_challenge = pkce[1];
    localStorage.setItem("code_verifier", code_verifier);

    let authUrl = new URL(this.authURL);
    authUrl.searchParams.append("client_id", this.clientId);
    authUrl.searchParams.append("code_challenge", code_challenge);
    authUrl.searchParams.append("code_challenge_method", "S256");
    authUrl.searchParams.append("response_type", "code");
    authUrl.searchParams.append("redirect_uri", this.redirectURI);

    window.location.href = authUrl.toString();
  };

  static getAccessToken = async (): Promise<TokenResponse | undefined> => {
    const codeVerifier = localStorage.getItem("code_verifier");
    const urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get("code");

    const urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "authorization_code");
    urlencoded.append("code", code!);
    urlencoded.append("redirect_uri", this.redirectURI);
    urlencoded.append("client_id", this.clientId);
    urlencoded.append("code_verifier", codeVerifier!);
    urlencoded.append("client_secret", this.clientSecret);

    const apiResult = await postData<AuthCodeResponse>(
      this.tokenURL,
      urlencoded,
      this.headers,
    );

    if (apiResult.success) {
      return this.setAuthResponseInLocalStorageAndReturnToken(apiResult);
    }
  };

  public static exchangeCodeForToken = async (
    code: string,
  ): Promise<TokenResponse | undefined> => {
    const body = new URLSearchParams({
      grant_type: "authorization_code",
      client_id: window._env_.REACT_APP_CLIENT_ID,
      client_secret: window._env_.REACT_APP_CLIENT_SECRET,
      code: code,
    });

    const headers: Record<string, string> = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const authTokenResponse = await postData<AuthCodeResponse>(
      this.tokenURL,
      body,
      headers,
    );
    console.log("Auth token response", authTokenResponse);
    if (authTokenResponse.success) {
      return this.setAuthResponseInLocalStorageAndReturnToken(
        authTokenResponse,
      );
    }
  };

  private static setAuthResponseInLocalStorageAndReturnToken = (
    authResponse: ApiSuccess<AuthCodeResponse>,
  ): TokenResponse => {
    const { access_token, expires_in, refresh_token, refresh_expires_in } =
      authResponse.data;

    localStorage.setItem("access_token", access_token);
    localStorage.setItem("access_token_expires_in", expires_in);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("refresh_expires_in", refresh_expires_in);

    return { access_token, refresh_token };
  };
}

export default authService;
